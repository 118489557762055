import React, { useState, useEffect } from 'react';
import Slider from 'react-slick'; // Import Slick Carousel
import { FaStar } from 'react-icons/fa'; // For star ratings
import { useLoadScript } from '@react-google-maps/api';


const ReviewsCarousel = () => {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(false);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_ROMANO_GOOGLE_MAPS_API_KEY,
    libraries: ['places'],
  });

  const placeId = 'ChIJaSFzfviWwoARV4oMM_eN2jM';

  useEffect(() => {
    if (!isLoaded || !placeId) return;

    const fetchReviews = async () => {
      try {
        setLoading(true);

        const maps = window.google.maps;
        const service = new maps.places.PlacesService(document.createElement('div'));
        const request = {
          placeId: placeId,
          fields: ['review'],
        };

        service.getDetails(request, (place, status) => {
          if (status === maps.places.PlacesServiceStatus.OK) {
            setReviews(place.reviews || []); // Store reviews in state
          } else {
            console.error('Failed to fetch reviews:', status);
          }
          setLoading(false);
        });
      } catch (error) {
        console.error('Error fetching reviews:', error);
        setLoading(false);
      }
    };

    fetchReviews();
  }, [isLoaded, placeId]);

  const settings = {
    infinite: true,
    speed: 400,
    slidesToShow: 4,
    slidesToScroll: 1,
    dots: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    centerMode: true,
    centerPadding: "20px",
    responsive: [
      {
        breakpoint: 1250, // Tablet and smaller devices
        settings: {
          slidesToShow: 3,
          centerPadding: "10px",
        },
      },
      {
        breakpoint: 1024, // Tablet and smaller devices
        settings: {
          slidesToShow: 3,
          centerPadding: "10px",
        },
      },
      {
        breakpoint: 768, // Mobile devices
        settings: {
          slidesToShow: 1,
          centerPadding: "0px",
        },
      },
      {
        breakpoint: 480, // Smaller mobile devices
        settings: {
          slidesToShow: 1,
          centerPadding: "0px",
          dots: false, // Optionally hide dots on very small screens
        },
      },
    ],
  };

  if (loading) {
    return <p>Loading reviews...</p>;
  }

  if (loadError) {
    return <p>Error loading Google Maps API</p>;
  }

  const renderStars = (rating) => {
    return [...Array(5)].map((_, index) => (
      <FaStar key={index} color={index < rating ? "#ffb400" : "#ddd"} />
    ));
  };

  return (
    <div className="reviews-carousel">
      {reviews.length > 0 ? (
        <Slider {...settings}>
          {reviews.map((review, index) => (
            <div key={index} className="carousel-item">
              <div className="review-card">
                <div className="review-header">
                  <div className="review-left">
                    <img 
                      src={review.profile_photo_url} 
                      alt={review.author_name} 
                      className="review-author-image" 
                    />
                    <div className="review-author-details">
                      <p><strong>{review.author_name}</strong></p>
                      <div className="rating">
                        {renderStars(review.rating)}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-text">
                  <p>{review.text}</p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      ) : (
        <p>No reviews available.</p>
      )}
    </div>
  );
};

export default ReviewsCarousel;
