// import { images } from "../../assets/images";
import logo from '../../assets/logo.png'

export const markers = [
    {
      id: 1,
      name: "Roof By Tom",
      // sliderImageOne: images.exteriorSlider1,
      // sliderImageTwo: images.exteriorSlider2,
      description: "Romano Builders do more than paint houses. We make homes. We put paints together, deploy them, and turn them into words to speak to the soul of everyone who sets their eyes on them.",
      link: "https://www.romanobuilders.com/service/interior-exterior-painting/",
      address: "Los Angeles, CA",
      placeId: "ChIJVz6lIJdhhYAR63hWV9SG8I0",
      reviewId: 1,
      position: { lat: 34.236206, lng: -118.485085 },
      contractorName: "Roof By Tom.",
      contractorPicture: logo,
      phoneNumber: "(925) 281-5396",
    },

  ];