import { images } from "../../assets/images";
import logo from '../../assets/logo.png'

export const markers = [
    {
      id: 1,
      name: "Interior & Exterior Painting",
      sliderImageOne: images.exteriorSlider1,
      sliderImageTwo: images.exteriorSlider2,
      description: "Romano Builders do more than paint houses. We make homes. We put paints together, deploy them, and turn them into words to speak to the soul of everyone who sets their eyes on them.",
      link: "https://www.romanobuilders.com/service/interior-exterior-painting/",
      address: "Los Angeles, CA",
      placeId: "ChIJaSFzfviWwoARV4oMM_eN2jM",
      reviewId: 1,
      position: { lat: 34.236206, lng: -118.485085 },
      contractorName: "Romanov Builders, Inc.",
      contractorPicture: logo,
      phoneNumber: "(888) 898-6640",
    },
    {
      id: 2,
      name: "Decks & Patio",
      sliderImageOne: images.deckSlider1,
      sliderImageTwo: images.deckSlider2,
      description: "Adding a deck or patio to your home is a beautiful idea. You get to enjoy a lovely outdoor space where you can take in the scenery and enjoy the cool evening breeze, possibly with a glass of wine in hand, if that’s your thing. What’s more, you get to do it without leaving the comfort of your home.",
      link: "https://www.romanobuilders.com/portfolio/decks-patio-gallery/",
      address: "Los Angeles, CA",
      placeId: "ChIJaSFzfviWwoARV4oMM_eN2jM",
      reviewId: 2,
      position: { lat: 34.166206, lng: -118.385085 },
      contractorName: "Romanov Builders, Inc.",
      contractorPicture: logo,
      phoneNumber: "(888) 898-6640",
    },
    {
      id: 3,
      name: "Decks & Patio",
      sliderImageOne: images.deckSlider11,
      sliderImageTwo: images.deckSlider22,
      description: "Adding a deck or patio to your home is a beautiful idea. You get to enjoy a lovely outdoor space where you can take in the scenery and enjoy the cool evening breeze, possibly with a glass of wine in hand, if that’s your thing. What’s more, you get to do it without leaving the comfort of your home.",
      link: "https://www.romanobuilders.com/portfolio/decks-patio-gallery/",
      address: "Los Angeles, CA",
      placeId: "ChIJaSFzfviWwoARV4oMM_eN2jM",
      reviewId: 3,
      position: { lat: 34.136206, lng: -118.685085 },
      contractorName: "Romanov Builders, Inc.",
      contractorPicture: logo,
      phoneNumber: "(888) 898-6640",
    },
    {
      id: 4,
      name: "Room Addition",
      sliderImageOne: images.roomSlide1,
      sliderImageTwo: images.roomSlide2,
      description: "So, then you have decided to add an extra room. That’s a wise decision. The next wise decision you will make is to choose a good Room Addition Services Contractor to do it for you.",
      link: "https://www.romanobuilders.com/portfolio/room-addition-gallery/",
      address: "Los Angeles, CA",
      placeId: "ChIJaSFzfviWwoARV4oMM_eN2jM",
      reviewId: 4,
      position: { lat: 33.776206, lng: -118.185085 },
      contractorName: "Romanov Builders, Inc.",
      contractorPicture: logo,
      phoneNumber: "(888) 898-6640",
    },
    {
      id: 5,
      name: "Bathroom Remodeling",
      sliderImageOne: images.bathroomSlide1,
      sliderImageTwo: images.bathroomSlide2,
      description: "From elegant to classy to tasteful, your bathroom could be every bit as beautiful as you are- and it should be. If for no other reason but for the fact that it’s where you drop the baggage of the day, unwind, relax, and be refreshed as the water from the shower runs down your skin.",
      link: "https://www.romanobuilders.com/portfolio/bathroom-remodelling/",
      address: "Los Angeles, CA",
      placeId: "ChIJaSFzfviWwoARV4oMM_eN2jM",
      reviewId: 5,
      position: { lat: 33.876206, lng: -118.285085 },
      contractorName: "Romanov Builders, Inc.",
      contractorPicture: logo,
      phoneNumber: "(888) 898-6640",
    },
    {
      id: 6,
      name: "Bathroom Remodeling",
      sliderImageOne: images.bathroomSlide11,
      sliderImageTwo: images.bathroomSlide22,
      description: "From elegant to classy to tasteful, your bathroom could be every bit as beautiful as you are- and it should be. If for no other reason but for the fact that it’s where you drop the baggage of the day, unwind, relax, and be refreshed as the water from the shower runs down your skin.",
      link: "https://www.romanobuilders.com/portfolio/bathroom-remodelling/",
      address: "Los Angeles, CA",
      placeId: "ChIJaSFzfviWwoARV4oMM_eN2jM",
      reviewId: 6,
      position: { lat: 34.176206, lng: -118.185085 },
      contractorName: "Romanov Builders, Inc.",
      contractorPicture: logo,
      phoneNumber: "(888) 898-6640",
    },
    {
      id: 7,
      name: "Kitchen Remodeling",
      sliderImageOne: images.kitchenSlide1,
      sliderImageTwo: images.kitchenSlide2,
      description: "While the living room may be where you entertain guests, the kitchen probably keeps you ‘living.’ Some say it is the heart of the home, and for good reason too. It’s where your food comes from, whether you are a chef, a mother, a wife, or a bachelor.",
      link: "https://www.romanobuilders.com/service/kitchen-remodeling/",
      address: "Los Angeles, CA",
      placeId: "ChIJaSFzfviWwoARV4oMM_eN2jM",
      reviewId: 7,
      position: { lat: 33.996206, lng: -118.275085 },
      contractorName: "Romanov Builders, Inc.",
      contractorPicture: logo,
      phoneNumber: "(888) 898-6640",
    },
    {
      id: 8,
      name: "Kitchen Remodeling",
      sliderImageOne: images.kitchen2Slide1,
      sliderImageTwo: images.kitchen2Slide2,
      description: "While the living room may be where you entertain guests, the kitchen probably keeps you ‘living.’ Some say it is the heart of the home, and for good reason too. It’s where your food comes from, whether you are a chef, a mother, a wife, or a bachelor.",
      link: "https://www.romanobuilders.com/service/kitchen-remodeling/",
      address: "Los Angeles, CA",
      placeId: "ChIJaSFzfviWwoARV4oMM_eN2jM",
      reviewId: 8,
      position: { lat: 33.996206, lng: -118.475085 },
      contractorName: "Romanov Builders, Inc.",
      contractorPicture: logo,
      phoneNumber: "(888) 898-6640",
    },
    {
      id: 9,
      name: "Kitchen Remodeling",
      sliderImageOne: images.kitchen3Slide1,
      sliderImageTwo: images.kitchen3Slide2,
      description: "While the living room may be where you entertain guests, the kitchen probably keeps you ‘living.’ Some say it is the heart of the home, and for good reason too. It’s where your food comes from, whether you are a chef, a mother, a wife, or a bachelor.",
      link: "https://www.romanobuilders.com/service/kitchen-remodeling/",
      address: "Los Angeles, CA",
      placeId: "ChIJaSFzfviWwoARV4oMM_eN2jM",
      reviewId: 9,
      position: { lat: 33.906206, lng: -118.115085 },
      contractorName: "Romanov Builders, Inc.",
      contractorPicture: logo,
      phoneNumber: "(888) 898-6640",
    },
    {
      id: 10,
      name: "Kitchen Remodeling",
      sliderImageOne: images.kitchen4Slide1,
      sliderImageTwo: images.kitchen4Slide2,
      description: "While the living room may be where you entertain guests, the kitchen probably keeps you ‘living.’ Some say it is the heart of the home, and for good reason too. It’s where your food comes from, whether you are a chef, a mother, a wife, or a bachelor.",
      link: "https://www.romanobuilders.com/service/kitchen-remodeling/",
      address: "Los Angeles, CA",
      placeId: "ChIJaSFzfviWwoARV4oMM_eN2jM",
      reviewId: 10,
      position: { lat: 33.906206, lng: -117.915085 },
      contractorName: "Romanov Builders, Inc.",
      contractorPicture: logo,
      phoneNumber: "(888) 898-6640",
    },
    {
      id: 11,
      name: "ADU & Garage Conversion",
      sliderImageOne: images.aduSlide1,
      sliderImageTwo: images.aduSlide2,
      description: "Let’s face it. Most of us rarely park our cars in the garage. Instead, it has become that cold dark place where we store things we don’t need or even regular trash. But your garage can be much more than that",
      link: "https://www.romanobuilders.com/service/conversation-garage-adu/",
      address: "Los Angeles, CA",
      placeId: "ChIJaSFzfviWwoARV4oMM_eN2jM",
      reviewId: 11,
      position: { lat: 33.806206, lng: -117.715085 },
      contractorName: "Romanov Builders, Inc.",
      contractorPicture: logo,
      phoneNumber: "(888) 898-6640",
    },
    {
      id: 12,
      name: "ADU & Garage Conversion",
      sliderImageOne: images.adu2Slide1,
      sliderImageTwo: images.adu2Slide2,
      description: "Let’s face it. Most of us rarely park our cars in the garage. Instead, it has become that cold dark place where we store things we don’t need or even regular trash. But your garage can be much more than that",
      link: "https://www.romanobuilders.com/service/conversation-garage-adu/",
      address: "Los Angeles, CA",
      placeId: "ChIJaSFzfviWwoARV4oMM_eN2jM",
      reviewId: 12,
      position: { lat: 33.706206, lng: -117.915085 },
      contractorName: "Romanov Builders, Inc.",
      contractorPicture: logo,
      phoneNumber: "(888) 898-6640",
    },
    {
      id: 13,
      name: "ADU & Garage Conversion",
      sliderImageOne: images.adu3Slide1,
      sliderImageTwo: images.adu3Slide2,
      description: "Let’s face it. Most of us rarely park our cars in the garage. Instead, it has become that cold dark place where we store things we don’t need or even regular trash. But your garage can be much more than that",
      link: "https://www.romanobuilders.com/service/conversation-garage-adu/",
      address: "Los Angeles, CA",
      placeId: "ChIJaSFzfviWwoARV4oMM_eN2jM",
      reviewId: 16,
      position: { lat: 33.606206, lng: -117.615085 },
      contractorName: "Romanov Builders, Inc.",
      contractorPicture: logo,
      phoneNumber: "(888) 898-6640",
    }

  ];