// import { images } from "../../assets/images";
import logo from '../../assets/logo.svg'

export const markers = [
    {
      id: 1,
      name: "Top Home Builders",
      // sliderImageOne: images.exteriorSlider1,
      // sliderImageTwo: images.exteriorSlider2,
      description: "do more than paint houses. We make homes. We put paints together, deploy them, and turn them into words to speak to the soul of everyone who sets their eyes on them.",
      link: "https://www.romanobuilders.com/service/interior-exterior-painting/",
      address: "Los Angeles, CA",
      placeId: "EjI1NDUwIFRob3Jud29vZCBEciBzdWl0ZSBlLCBTYW4gSm9zZSwgQ0EgOTUxMjMsIFVTQSIjGiEKFgoUChIJJ_RYn9UzjoARF5KBZ5ZWXT0SB3N1aXRlIGU",
      reviewId: 1,
      position: { lat: 34.236206, lng: -118.485085 },
      contractorName: "Top Home Builders",
      contractorPicture: logo,
      phoneNumber: "(925) 281-5396",
    },

  ];