import { images } from "../../assets/projects/images"
import logo from '../../assets/logo.png'


export const markers = [
    {
      id: 1,
      name: "New Sliding Door",
      sliderImageOne: images.new_sliding_door1_1,
      sliderImageTwo: images.new_sliding_door1_2,
      description: "Looking for new sliding door installation? Our sliding door specialists ensure precise fitting, smooth operation, and enhanced home aesthetics. Trust us for professional service and quality craftsmanship.",
      link: "https://slidingdoorspecialist.com/new-sliding-door/",
      address: "",
      placeId: "EiI3Rkg1K1ZDLCBIb21lc3RlYWQgVmFsbGV5LCBDQSwgVVNBIiY6JAoKDUuqbhQVGTCJuhAKGhQKEglRVT5TbtXagBHyVbuT4jxc4Q7FH5+VC",
      reviewId: 1,
      position: { lat: 34.136206, lng: -118.485085 },
      contractorName: "Sliding Door Specialist",
      contractorPicture: logo,
      phoneNumber: "626-616-5977",
    },
    {
      id: 2,
      name: "Aluminum Sliding Door Repair",
      sliderImageOne: images.alum_sliding_door1_1,
      sliderImageTwo: images.alum_sliding_door1_2,
      description: "Looking for aluminum sliding door repair? Our sliding door specialists ensure precise fitting, smooth operation, and enhanced home aesthetics. Trust us for professional service and quality craftsmanship.",
      link: "https://slidingdoorspecialist.com/aluminum-sliding-door-repair/",
      address: "",
      placeId: "EiI3Rkg1K1ZDLCBIb21lc3RlYWQgVmFsbGV5LCBDQSwgVVNBIiY6JAoKDUuqbhQVGTCJuhAKGhQKEglRVT5TbtXagBHyVbuT4jxc4Q7FH5+VC",
      reviewId: 2,
      position: { lat: 34.236206, lng: -118.415085 },
      contractorName: "Sliding Door Specialist",
      contractorPicture: logo,
      phoneNumber: "626-616-5977",
    },
    {
      id: 3,
      name: "New Sliding Door",
      sliderImageOne: images.new_sliding_door2_1,
      sliderImageTwo: images.new_sliding_door2_2,
      description: "Looking for new sliding door installation? Our sliding door specialists ensure precise fitting, smooth operation, and enhanced home aesthetics. Trust us for professional service and quality craftsmanship.",
      link: "https://slidingdoorspecialist.com/new-sliding-door-2/",
      address: "",
      placeId: "EiI3Rkg1K1ZDLCBIb21lc3RlYWQgVmFsbGV5LCBDQSwgVVNBIiY6JAoKDUuqbhQVGTCJuhAKGhQKEglRVT5TbtXagBHyVbuT4jxc4Q7FH5+VC",
      reviewId: 3,
      position: { lat: 34.011206, lng: -118.315085 },
      contractorName: "Sliding Door Specialist",
      contractorPicture: logo,
      phoneNumber: "626-616-5977",
    },
    {
      id: 4,
      name: "Sliding Door Rollers Repair",
      sliderImageOne: images.sliding_door_rollers1_1,
      sliderImageTwo: images.sliding_door_rollers1_2,
      description: "Looking for new sliding door rollers? Our sliding door specialists ensure precise fitting, smooth operation, and enhanced home aesthetics. Trust us for professional service and quality craftsmanship.",
      link: "https://slidingdoorspecialist.com/sliding-door-rollers-repair/",
      address: "",
      placeId: "EiI3Rkg1K1ZDLCBIb21lc3RlYWQgVmFsbGV5LCBDQSwgVVNBIiY6JAoKDUuqbhQVGTCJuhAKGhQKEglRVT5TbtXagBHyVbuT4jxc4Q7FH5+VC",
      reviewId: 4,
      position: { lat: 34.011206, lng: -118.115085 },
      contractorName: "Sliding Door Specialist",
      contractorPicture: logo,
      phoneNumber: "626-616-5977",
    },
    {
      id: 5,
      name: "Patio Sliding Door Repair",
      sliderImageOne: images.patio_door_repair1_1,
      sliderImageTwo: images.patio_door_repair1_2,
      description: "Looking for new patio sliding door? Our sliding door specialists ensure precise fitting, smooth operation, and enhanced home aesthetics. Trust us for professional service and quality craftsmanship.",
      link: "https://slidingdoorspecialist.com/patio-sliding-door-repair/",
      address: "",
      placeId: "EiI3Rkg1K1ZDLCBIb21lc3RlYWQgVmFsbGV5LCBDQSwgVVNBIiY6JAoKDUuqbhQVGTCJuhAKGhQKEglRVT5TbtXagBHyVbuT4jxc4Q7FH5+VC",
      reviewId: 5,
      position: { lat: 34.211206, lng: -118.205085 },
      contractorName: "Sliding Door Specialist",
      contractorPicture: logo,
      phoneNumber: "626-616-5977",
    },
    {
      id: 6,
      name: "Sliding Door Handles Repair",
      sliderImageOne: images.sliding_door_handles1_1,
      sliderImageTwo: images.sliding_door_handles1_2,
      description: "Looking for new sliding door handles? Our sliding door specialists ensure precise fitting, smooth operation, and enhanced home aesthetics. Trust us for professional service and quality craftsmanship.",
      link: "https://slidingdoorspecialist.com/sliding-door-handles-repair/",
      address: "",
      placeId: "EiI3Rkg1K1ZDLCBIb21lc3RlYWQgVmFsbGV5LCBDQSwgVVNBIiY6JAoKDUuqbhQVGTCJuhAKGhQKEglRVT5TbtXagBHyVbuT4jxc4Q7FH5+VC",
      reviewId: 6,
      position: { lat: 34.121206, lng: -117.605085 },
      contractorName: "Sliding Door Specialist",
      contractorPicture: logo,
      phoneNumber: "626-616-5977",
    },
    {
      id: 7,
      name: "Wooden Sliding Door Repair",
      sliderImageOne: images.wooden_sliding_door1_1,
      sliderImageTwo: images.wooden_sliding_door1_2,
      description: "Looking to repair your wooden sliding door? Our sliding door specialists ensure precise fitting, smooth operation, and enhanced home aesthetics. Trust us for professional service and quality craftsmanship.",
      link: "https://slidingdoorspecialist.com/wooden-sliding-door-repair/",
      address: "",
      placeId: "EiI3Rkg1K1ZDLCBIb21lc3RlYWQgVmFsbGV5LCBDQSwgVVNBIiY6JAoKDUuqbhQVGTCJuhAKGhQKEglRVT5TbtXagBHyVbuT4jxc4Q7FH5+VC",
      reviewId: 7,
      position: { lat: 33.921206, lng: -117.905085 },
      contractorName: "Sliding Door Specialist",
      contractorPicture: logo,
      phoneNumber: "626-616-5977",
    },
    {
      id: 8,
      name: "Sliding Door Track Repair",
      sliderImageOne: images.track_repair1_1,
      sliderImageTwo: images.track_repair1_2,
      description: "Looking to repair your sliding door tracks? Our sliding door specialists ensure precise fitting, smooth operation, and enhanced home aesthetics. Trust us for professional service and quality craftsmanship.",
      link: "https://slidingdoorspecialist.com/sliding-door-tracks-repair/",
      address: "",
      placeId: "EiI3Rkg1K1ZDLCBIb21lc3RlYWQgVmFsbGV5LCBDQSwgVVNBIiY6JAoKDUuqbhQVGTCJuhAKGhQKEglRVT5TbtXagBHyVbuT4jxc4Q7FH5+VC",
      reviewId: 8,
      position: { lat: 33.821206, lng: -118.205085 },
      contractorName: "Sliding Door Specialist",
      contractorPicture: logo,
      phoneNumber: "626-616-5977",
    },
    {
      id: 9,
      name: "Sliding Door Repair",
      sliderImageOne: images.sliding_door_repair1_1,
      sliderImageTwo: images.sliding_door_repair1_2,
      description: "Looking to repair your sliding door? Our sliding door specialists ensure precise fitting, smooth operation, and enhanced home aesthetics. Trust us for professional service and quality craftsmanship.",
      link: "https://slidingdoorspecialist.com/sliding-door-repair/",
      address: "",
      placeId: "EiI3Rkg1K1ZDLCBIb21lc3RlYWQgVmFsbGV5LCBDQSwgVVNBIiY6JAoKDUuqbhQVGTCJuhAKGhQKEglRVT5TbtXagBHyVbuT4jxc4Q7FH5+VC",
      reviewId: 9,
      position: { lat: 33.821206, lng: -118.105085 },
      contractorName: "Sliding Door Specialist",
      contractorPicture: logo,
      phoneNumber: "626-616-5977",
    },
    {
      id: 10,
      name: "Sliding Door Repair",
      sliderImageOne: images.sliding_door_repair2_1,
      sliderImageTwo: images.sliding_door_repair2_2,
      description: "Looking to repair your sliding door? Our sliding door specialists ensure precise fitting, smooth operation, and enhanced home aesthetics. Trust us for professional service and quality craftsmanship.",
      link: "https://slidingdoorspecialist.com/sliding-door-repair-2/",
      address: "",
      placeId: "EiI3Rkg1K1ZDLCBIb21lc3RlYWQgVmFsbGV5LCBDQSwgVVNBIiY6JAoKDUuqbhQVGTCJuhAKGhQKEglRVT5TbtXagBHyVbuT4jxc4Q7FH5+VC",
      reviewId: 10,
      position: { lat: 33.721206, lng: -118.305085 },
      contractorName: "Sliding Door Specialist",
      contractorPicture: logo,
      phoneNumber: "626-616-5977",
    },
    {
      id: 11,
      name: "Sliding Door Rollers Repair",
      sliderImageOne: images.sliding_door_rollers2_1,
      sliderImageTwo: images.sliding_door_rollers2_2,
      description: "Looking to repair your sliding door rollers? Our sliding door specialists ensure precise fitting, smooth operation, and enhanced home aesthetics. Trust us for professional service and quality craftsmanship.",
      link: "https://slidingdoorspecialist.com/sliding-door-rollers-repair-2/",
      address: "",
      placeId: "EiI3Rkg1K1ZDLCBIb21lc3RlYWQgVmFsbGV5LCBDQSwgVVNBIiY6JAoKDUuqbhQVGTCJuhAKGhQKEglRVT5TbtXagBHyVbuT4jxc4Q7FH5+VC",
      reviewId: 11,
      position: { lat: 33.861206, lng: -118.325085 },
      contractorName: "Sliding Door Specialist",
      contractorPicture: logo,
      phoneNumber: "626-616-5977",
    },
    {
      id: 12,
      name: "Sliding Door Handles Repair",
      sliderImageOne: images.sliding_door_handle_repair,
      sliderImageTwo: images.sliding_door_handle_repair2,
      description: "Looking to repair your sliding door handles? Our sliding door specialists ensure precise fitting, smooth operation, and enhanced home aesthetics. Trust us for professional service and quality craftsmanship.",
      link: "https://slidingdoorspecialist.com/sliding-door-handle-repair/",
      address: "",
      placeId: "EiI3Rkg1K1ZDLCBIb21lc3RlYWQgVmFsbGV5LCBDQSwgVVNBIiY6JAoKDUuqbhQVGTCJuhAKGhQKEglRVT5TbtXagBHyVbuT4jxc4Q7FH5+VC",
      reviewId: 12,
      position: { lat: 33.921206, lng: -118.225085 },
      contractorName: "Sliding Door Specialist",
      contractorPicture: logo,
      phoneNumber: "626-616-5977",
    },

    

  ];