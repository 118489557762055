// import { images } from "../../assets/images";
import logo from '../../assets/logo.png'

export const markers = [
    {
      id: 1,
      name: "Top Home Roofers",
      // sliderImageOne: images.exteriorSlider1,
      // sliderImageTwo: images.exteriorSlider2,
      description: "Romano Builders do more than paint houses. We make homes. We put paints together, deploy them, and turn them into words to speak to the soul of everyone who sets their eyes on them.",
      link: "https://www.romanobuilders.com/service/interior-exterior-painting/",
      address: "Los Angeles, CA",
      placeId: "EjM1NDUwIFRob3Jud29vZCBEciBzdWl0ZSBlMiwgU2FuIEpvc2UsIENBIDk1MTIzLCBVU0EiJBoiChYKFAoSCSf0WJ_VM46AEReSgWeWVl09EghzdWl0ZSBlMg",
      reviewId: 1,
      position: { lat: 34.236206, lng: -118.485085 },
      contractorName: "Top Home Roofers.",
      contractorPicture: logo,
      phoneNumber: "(925) 281-5396",
    },

  ];