import exteriorSlider1 from './interior-exterior/exterior-slide-1.jpg';
import exteriorSlider2 from './interior-exterior/exterior-slide-2.jpg';
import deckSlider1 from './deck/deck-slide-1.jpg';
import deckSlider2 from './deck/deck-slide-2.jpg';
import deckSlider11 from './deck-2/deck-slide-1.jpg';
import deckSlider22 from './deck-2/deck-slide-2.jpg';
import roomSlide1 from './room-addition/room-slide-1.jpg';
import roomSlide2 from './room-addition/room-slide-2.jpg';
import bathroomSlide1 from './bathroom/bathroom-slide-1.jpg';
import bathroomSlide2 from './bathroom/bathroom-slide-2.jpg';
import bathroomSlide11 from './bathroom-2/bathroom-slide-1.jpg';
import bathroomSlide22 from './bathroom-2/bathroom-slide-2.jpg';
import kitchenSlide1 from './kitchen/kitchen-slide-1.jpg';
import kitchenSlide2 from './kitchen/kitchen-slide-2.jpg';
import kitchen2Slide1 from './kitchen-2/kitchen-slide-1.jpg';
import kitchen2Slide2 from './kitchen-2/kitchen-slide-2.jpg';
import kitchen3Slide1 from './kitchen-3/kitchen-slide-1.jpg';
import kitchen3Slide2 from './kitchen-3/kitchen-slide-2.jpg';
import kitchen4Slide1 from './kitchen-4/kitchen-slide-1.jpg';
import kitchen4Slide2 from './kitchen-4/kitchen-slide-2.jpg';
import flooringSlide1 from './flooring/flooring-slide-1.jpg';
import flooringSlide2 from './flooring/flooring-slide-2.jpg';
import drivewaySlide1 from './driveway/driveway-slide-1.jpg';
import drivewaySlide2 from './driveway/driveway-slide-2.jpg';
import stuccoSlide1 from './stucco/stucco-slide-1.jpg';
import stuccoSlide2 from './stucco/stucco-slide-2.jpg';
import aduSlide1 from './adu/adu-slide-1.jpg';
import aduSlide2 from './adu/adu-slide-2.jpg';
import adu2Slide1 from './adu-2/adu-slide-1.jpg';
import adu2Slide2 from './adu-2/adu-slide-2.jpg';
import adu3Slide1 from './adu-3/adu-slide-1.jpg';
import adu3Slide2 from './adu-3/adu-slide-2.jpg';



export const images = {
    exteriorSlider1,
    exteriorSlider2,
    deckSlider1,
    deckSlider2,
    deckSlider11,
    deckSlider22,
    roomSlide1,
    roomSlide2,
    bathroomSlide1,
    bathroomSlide2,
    bathroomSlide11,
    bathroomSlide22,
    kitchenSlide1,
    kitchenSlide2,
    kitchen2Slide1,
    kitchen2Slide2,
    kitchen3Slide1,
    kitchen3Slide2,
    kitchen4Slide1,
    kitchen4Slide2,
    flooringSlide1,
    flooringSlide2,
    drivewaySlide1,
    drivewaySlide2,
    stuccoSlide1,
    stuccoSlide2,
    aduSlide1,
    aduSlide2,
    adu2Slide1,
    adu2Slide2,
    adu3Slide1,
    adu3Slide2
}