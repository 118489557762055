import React from 'react';
import RomanoGoogleMap from './Romano/Google Map/Map/Map'; // Adjust path as necessary

const TestPage = () => {
    return (
        <div>
            <RomanoGoogleMap />
        </div>
    );
}

export default TestPage;