import new_sliding_door1_1 from './new sliding door/IMG_6492.jpg'
import new_sliding_door1_2 from './new sliding door/IMG_6494.jpg'
import alum_sliding_door1_1 from './Aluminum Sliding Door Repair/IMG_3803.jpg'
import alum_sliding_door1_2 from './Aluminum Sliding Door Repair/IMG_3806.jpg'
import new_sliding_door2_1 from './new sliding door 2/IMG_1208.jpg'
import new_sliding_door2_2 from './new sliding door 2/IMG_1209.jpg'
import sliding_door_rollers1_1 from './sliding door rollers repair/IMG_0135.jpg'
import sliding_door_rollers1_2 from './sliding door rollers repair/c7a677dd-4757-406a-aa47-5d75b29f3523.jpg'
import patio_door_repair1_1 from './patio sliding door repair/IMG_3686.jpg'
import patio_door_repair1_2 from './patio sliding door repair/IMG_3687.jpg'
import sliding_door_handles1_1 from './sliding door handles repair/IMG_2386.jpg'
import sliding_door_handles1_2 from './sliding door handles repair/IMG_8207.jpg'
import wooden_sliding_door1_1 from './wooden sliding door repair/IMG_5037.jpg'
import wooden_sliding_door1_2 from './wooden sliding door repair/IMG_5022.jpg'
import track_repair1_1 from './sliding door track repair/IMG_8470.jpg'
import track_repair1_2 from './sliding door track repair/IMG_8681.jpg'
import sliding_door_repair1_1 from './sliding door repair/IMG_8233.jpg'
import sliding_door_repair1_2 from './sliding door repair/IMG_8234.jpg'
import sliding_door_repair2_1 from './sliding door repair 2/IMG_7301.jpg'
import sliding_door_repair2_2 from './sliding door repair 2/IMG_8544.jpg'
import sliding_door_rollers2_1 from './sliding door rollers repair 2/IMG_7655.jpg'
import sliding_door_rollers2_2 from './sliding door rollers repair 2/IMG_7658.jpg'
import sliding_door_handle_repair from './sliding door handle repair/IMG_1081.jpg'
import sliding_door_handle_repair2 from './sliding door handle repair/IMG_1798.jpg'

export const images = { 
    new_sliding_door1_1,
    new_sliding_door1_2,
    new_sliding_door2_1,
    new_sliding_door2_2,
    alum_sliding_door1_1,
    alum_sliding_door1_2,
    sliding_door_rollers1_1,
    sliding_door_rollers1_2,
    patio_door_repair1_1,
    patio_door_repair1_2,
    sliding_door_handles1_1,
    sliding_door_handles1_2,
    wooden_sliding_door1_1,
    wooden_sliding_door1_2,
    track_repair1_1,
    track_repair1_2,
    sliding_door_repair1_1,
    sliding_door_repair1_2,
    sliding_door_repair2_1,
    sliding_door_repair2_2,
    sliding_door_rollers2_1,
    sliding_door_rollers2_2,
    sliding_door_handle_repair,
    sliding_door_handle_repair2
}