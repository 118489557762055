import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import NotFoundPage from './Pages/NotFoundPage/NotFoundPage';
import Home from './Pages/Home/Home';
import RomanoGoogleMap from './Pages/Romano/Google Map/Map/Map'
import RomanoReviews from './Pages/Romano/Reviews Carousel/ReviewsCarousel'
import SlidingGoogleMap from './Pages/SlidingDoors/Google Map/Map/Map'
import RoofByTomMap from './Pages/RoofByTom/Google Map/Map/Map'
import HomeByTomMap from './Pages/HomeByTom/Google Map/Map/Map'
import WhiteLotusMap from './Pages/WhiteLotus/Google Map/Map/Map'
import BuildUrDreamMap from './Pages/BuildUrDream/Google Map/Map/Map'
import TopHomeRoofers from './Pages/TopRoof/Google Map/Map/Map'
import TopHomeBuilders from './Pages/TopHome/Google Map/Map/Map'

import TestPage from './Pages/testmap';

const router = createBrowserRouter([
  {path: '/', element: <Home />, errorElement: <NotFoundPage /> },
  {path: '/romanomap', element: <RomanoGoogleMap />},
  {path: '/romanoreviews', element: <RomanoReviews />},
  {path: '/slidingmap', element: <SlidingGoogleMap />},
  {path: '/rbtmap', element: <RoofByTomMap />},
  {path: '/hbtmap', element: <HomeByTomMap />},
  {path: '/whitelotusmap', element: <WhiteLotusMap />},
  {path: '/buildurdreammap', element: <BuildUrDreamMap />},
  {path: '/tophomeroofersmap', element: <TopHomeRoofers />},
  {path: '/tophomebuildersmap', element: <TopHomeBuilders />},

  { path: '/test', element: <TestPage /> }, // New test route
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
   <RouterProvider router={router} />
  </React.StrictMode>
);


