import React, { Fragment, useState } from "react";
import {
  GoogleMap,
  InfoWindowF,
  MarkerF,
  useLoadScript,
} from "@react-google-maps/api";
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';
import { styles } from "./GoogleMapStyles";
import { markers } from "./Markers";
import './Map.css'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import marker from '../../assets/navigator.png'

const libraries = ['places']; // Add this line

const Map = () => {

  const googleMapsApiKey = process.env.REACT_APP_BUILD_DREAM_GOOGLE_MAPS_API_KEY;

  

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: googleMapsApiKey,
    libraries: libraries // Include the Places library
  });

  const [activeMarker, setActiveMarker] = useState(null);
  const [reviews, setReviews] = useState({}); // State to store reviews per marker

  const handleMouseOver = (id) => {
    setActiveMarker(id);
    fetchReviews(id); // Fetch reviews when marker is hovered or clicked
  };

  const handleMouseOut = () => {
    // No action here to keep the InfoWindow open after hover ends
  };

  const fetchReviews = (id) => {
    const placeId = markers.find(marker => marker.id === id).placeId; // Get placeId from the marker

    const service = new window.google.maps.places.PlacesService(document.createElement('div'));
    const request = {
      placeId: placeId, // Use placeId to fetch reviews
      fields: ['review'], // Specify that you want reviews
    };
    
    service.getDetails(request, (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        setReviews(prev => ({ ...prev, [id]: place.reviews || [] })); // Store reviews in state
        console.log(place.reviews);
      }
    });
  };

  // Handle load error
  if (loadError) return <div>Error loading map</div>;
  if (!isLoaded) return <div>Loading...</div>;

  return (
    <Fragment>

    <Navbar expand="lg" className="bg-body-tertiary" data-bs-theme="dark">
            <Container fluid>
                <Navbar.Brand>NuviSav</Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                    <Nav
                        className="me-auto my-2 my-lg-0"
                        style={{ maxHeight: '100px' }}
                        navbarScroll
                    >
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>

      <div className="container">
        <div style={{ height: "85vh", width: "100%" }}>
          <GoogleMap
            center={{ lat: 34.0549, lng: -118.2426 }}
            zoom={9}
            mapContainerStyle={{ width: "100%", height: "85vh" }}
            options={{
              styles: styles["silver"], // Change map style
              disableDefaultUI: true, // Disable default UI
              zoomControl: true, // Enable zoom control
            }}
          >
            {markers.map(({
              id,
              name,
              position,
              address,
              description,
              link,
              sliderImageOne,
              sliderImageTwo,
              reviewId,
              contractorName,
              contractorPicture,
              phoneNumber
            }) => (
              <MarkerF
                key={id}
                position={position}
                icon={{
                  url:  marker, // Use the marker's image as the icon
                  scaledSize: new window.google.maps.Size(40, 40), // Adjust size as needed
                }}
                title={name}
                onClick={() => {
                  setActiveMarker(id);
                  window.open(link, "_blank"); // Opens the link in a new tab
                }}
                onMouseOver={() => handleMouseOver(id)} // Open InfoWindow on hover
                onMouseOut={handleMouseOut} // No action on mouse out
              >
                {activeMarker === id && (
                  <InfoWindowF onCloseClick={() => setActiveMarker(null)}
                  >
                    <div className="container">
                      <div className="flex-center">
                        <div className="card-container">
                          <div className="card">
                            <div className="card-image">
                              <ReactCompareSlider
                                changePositionOnHover
                                style={{
                                  height: '200px',
                                  width: '300px',
                                  margin: 'auto',
                                }}
                                itemOne={<ReactCompareSliderImage src={sliderImageOne} alt="Exterior" />}
                                itemTwo={<ReactCompareSliderImage src={sliderImageTwo} alt="Interior" />}
                              />
                            </div>
                            <div className="card-content">
                              <p className="card-title">{name}</p>
                              <p className="card-description">{description}</p>
                                <a href={link} target="_blank" rel="noreferrer" className="card-link">
                              <button className="custom-btn btn-12"><span>Click!</span><span>Gallery</span></button>
                              </a>
                              <p className="address">{address}</p>
                            </div>
                            <div className="google-reviews">
                              {reviews[id] ? (
                                reviews[id].length > 0 ? (
                                  <div className="review-card">
                                    {reviews[id][reviewId] ? (
                                      <div>
                                        <div className="review-header">
                                          {reviews[id][reviewId].profile_photo_url ? (
                                            <img
                                              src={reviews[id][reviewId].profile_photo_url}
                                              alt={`${reviews[id][reviewId].author_name}'s profile`}
                                              className="review-profile-image"
                                            />
                                          ) : (
                                            <div className="review-placeholder-image"></div>
                                          )}
                                          <div>
                                            <p className="review-name">{reviews[id][reviewId].author_name}</p>
                                            <p className="review-date">
                                              {new Date(reviews[id][1].time * 1000).toLocaleDateString()}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="review-rating">
                                          {Array(reviews[id][reviewId].rating).fill().map((_, i) => (
                                            <svg key={i} className="star-icon" viewBox="0 0 24 24">
                                              <path d="M12 .587l3.668 7.429 8.2 1.19-5.934 5.778 1.402 8.167L12 18.897l-7.336 3.854 1.402-8.167L.132 9.206l8.2-1.19L12 .587z" />
                                            </svg>
                                          ))}
                                        </div>
                                        <p className="review-content">{reviews[id][reviewId].text}</p>
                                      </div>
                                    ) : (
                                      <p>Review not found</p>
                                    )}
                                  </div>
                                ) : (
                                  <p>No reviews available</p>
                                )
                              ) : (
                                <p>Loading reviews...</p>
                              )}
                            </div>
                            <div className="realtor-section">
                              <div className="realtor-info">
                                <div className="realtor-image">
                               <img src={contractorPicture} alt="." />
                                </div>
                                <div>
                                  <p className="realtor-name">{contractorName}</p>
                                  <p className="realtor-contact">
                                    <a href={`tel:${phoneNumber}`}>
                                      {phoneNumber}
                                    </a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </InfoWindowF>
                )}
              </MarkerF>
            ))}
          </GoogleMap>
        </div>
      </div>
      
    </Fragment>
    
  );
};

export default Map;
